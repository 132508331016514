import { DocumentRenewalStatusEnum } from '../../__generated__/graphql';

type DocumentsAllowedToUpload = {
  documentDriverLicenseStatus: DocumentRenewalStatusEnum | null;
  documentInsuranceStatus: DocumentRenewalStatusEnum | null;
};

enum PageStatus {
  UNDER_REVIEW = 'under_review',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  SHOW_FORM = 'show_form',
  INCONCISTENT_DATA = 'inconsistent_data',
}

function uploadNeeded(status?: DocumentRenewalStatusEnum | null): boolean {
  if (!status) return false;

  return [
    DocumentRenewalStatusEnum.FileRequested,
    DocumentRenewalStatusEnum.Expired,
    DocumentRenewalStatusEnum.AboutToExpire,
    DocumentRenewalStatusEnum.NotExists,
  ].includes(status);
}

function needsReview(status: DocumentRenewalStatusEnum): boolean {
  return status === 'pending_review';
}

function getPageStatus(
  documentsStatuses: DocumentRenewalStatusEnum[],
): PageStatus {
  let statuses = [...documentsStatuses];

  if (statuses.length === 0) return PageStatus.INCONCISTENT_DATA;

  const allDocumentsAreApproved = statuses.every(
    status => status === DocumentRenewalStatusEnum.Approved,
  );

  const someDocumentsAreApproved = statuses.some(
    status => status === DocumentRenewalStatusEnum.Approved,
  );

  if (someDocumentsAreApproved && !allDocumentsAreApproved)
    statuses = statuses.filter(
      status => status !== DocumentRenewalStatusEnum.Approved,
    );

  if (statuses.some(status => uploadNeeded(status)))
    return PageStatus.SHOW_FORM;

  if (statuses.every(status => status === DocumentRenewalStatusEnum.Rejected))
    return PageStatus.REJECTED;

  if (allDocumentsAreApproved) return PageStatus.APPROVED;

  if (
    statuses.every(status => status === DocumentRenewalStatusEnum.PendingReview)
  )
    return PageStatus.UNDER_REVIEW;

  return PageStatus.INCONCISTENT_DATA;
}

export { getPageStatus, needsReview, PageStatus, uploadNeeded };
export type { DocumentsAllowedToUpload };

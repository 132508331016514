import { useSearchParams } from 'react-router-dom';

export default function useSearchParam<T = string>({
  key,
  isJSON = false,
}: {
  key: string;
  isJSON?: boolean;
}): [T | null, (value: T | null, keysToDelete?: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  let paramValue = searchParams.get(key) as T | null;
  if (paramValue && isJSON)
    paramValue = paramValue
      ? JSON.parse(decodeURIComponent(paramValue as string))
      : null;

  const setParamValue = (value: T | null, keysToDelete?: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value === null || value === undefined) {
      newSearchParams.delete(key);
    } else {
      const valueTmp = isJSON
        ? encodeURIComponent(JSON.stringify(value))
        : value;
      newSearchParams.set(key, valueTmp as unknown as string);
    }
    (keysToDelete || []).forEach(keyToDelete => {
      newSearchParams.delete(keyToDelete);
    });
    setSearchParams(newSearchParams);
  };

  return [paramValue, setParamValue];
}

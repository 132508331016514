import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import apiFetch from '../../../util/api-fetch';

export type GetBackgroundCheckReportFormUrlParams = {
  applicantId: string;
};

export type ReportFormResponse = {
  url: string;
};

async function getBackgroundCheckReportFormUrl({
  applicantId,
}: GetBackgroundCheckReportFormUrlParams): Promise<
  ReportFormResponse | undefined
> {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/certifications/${applicantId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return apiFetch<ReportFormResponse>({ url, options });
}

export default getBackgroundCheckReportFormUrl;

import { DirectUpload } from 'activestorage';
import { DocumentType } from '../types/document.types';

export type DocumentToUpload = {
  file: File;
  documentType: DocumentType;
};

export type UploadMultipleImagesParams = {
  documents: DocumentToUpload[];
};

export type UploadSingleImageParams = {
  document: DocumentToUpload;
};

export type HandleImageUploadResponse = {
  signedId: string;
  documentType: DocumentType;
};

const uploadToS3 = async (
  directUploadUrl: string,
  file: File,
): Promise<string> => {
  const upload = new DirectUpload(file, directUploadUrl);

  return new Promise((resolve, reject) => {
    upload.create((error: Error, blob: ActiveStorage.Blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob.signed_id);
      }
    });
  });
};

const handleImageUpload = async ({
  document,
}: UploadSingleImageParams): Promise<string> => {
  const directUploadsUrl = '/rails/active_storage/direct_uploads';

  return uploadToS3(directUploadsUrl, document.file);
};

const useFileUploadS3 = () => {
  const handleSingleImageUpload = async ({
    document,
  }: UploadSingleImageParams): Promise<HandleImageUploadResponse> => {
    const signedId = await handleImageUpload({ document });

    return {
      documentType: document.documentType,
      signedId,
    };
  };

  const handleMultipleImageUpload = async ({
    documents,
  }: UploadMultipleImagesParams): Promise<HandleImageUploadResponse[]> => {
    return Promise.all(
      documents.map(async (document: DocumentToUpload) => {
        const signedId = await handleImageUpload({ document });

        return {
          documentType: document.documentType,
          signedId,
        };
      }),
    );
  };

  return { handleSingleImageUpload, handleMultipleImageUpload };
};

export default useFileUploadS3;

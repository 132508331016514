import {
  CurrentStepEnum,
  RejectionReasonTypeEnum,
} from '../__generated__/graphql';

export const STEPS = 'steps';
export const JOIN_OUR_NETWORK = 'join-our-network';
export const AVAILABLE_OPENINGS = 'available-openings';
export const APPLICATION_STEP_VEHICLE_DETAILS = 'vehicle-details';
export const APPLICATION_STEP_REVIEW_SIGN = 'review-and-sign';
export const APPLICATION_STEP_PERSONAL_INFO = 'personal-info';
export const APPLICATION_STEP_DRIVER_DETAILS = 'driver-details';
export const APPLICATION_STEP_DRIVER_SKILLS = 'vehicle-driver-skills';
export const APPLICATION_STEP_DRIVER_CERTIFICATION = 'driver-certification';
export const APPLICATION_STEP_DRIVER_BACKGROUND_CHECK = 'background-check';
export const DOCUMENTS_RENEWALS = 'documents-renewals';
export const SIGN_IN = 'sign-in';
export const SIGN_IN_ADMIN = 'sign-in-admin';
export const APPLICATION_STATUS = 'status';

export type ApplicationStepRoutes =
  | typeof APPLICATION_STEP_PERSONAL_INFO
  | typeof APPLICATION_STEP_DRIVER_SKILLS
  | typeof APPLICATION_STEP_DRIVER_DETAILS
  | typeof APPLICATION_STEP_VEHICLE_DETAILS
  | typeof APPLICATION_STEP_REVIEW_SIGN
  | typeof APPLICATION_STEP_DRIVER_CERTIFICATION
  | typeof APPLICATION_STEP_DRIVER_BACKGROUND_CHECK;

export const STATUS_KEY_ALREADY_USER = 'already-registered';
export const STATUS_KEY_ALREADY_APPLIED = 'already-applied';
export const STATUS_KEY_APPLICATION_SENT = 'sent';
export const STATUS_KEY_REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS =
  RejectionReasonTypeEnum.PersonalInfoNotMeetRequirements;
export const STATUS_KEY_REJECTED_VEHICLE_TYPE_MODIFIED =
  RejectionReasonTypeEnum.VehicleTypeModified;
export const STATUS_KEY_DRIVER_ADDRESS_OUTSIDE_OPENING_STATE =
  RejectionReasonTypeEnum.DriverAddressOutsideOpeningState;
export const STATUS_KEY_REJECTED_BACKGROUND_CHECK_FAIL =
  RejectionReasonTypeEnum.BackgroundCheckFail;
export const STATUS_KEY_REJECTED_OPENING_DEACTIVATED =
  RejectionReasonTypeEnum.OpeningDeactivated;
export const STATUS_KEY_REJECTED_INACTIVE_APPLICATION =
  RejectionReasonTypeEnum.InactiveApplication;
export const STATUS_KEY_REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS =
  RejectionReasonTypeEnum.ApplicantDoesNotMeetRequirements;
export const STATUS_KEY_REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED =
  RejectionReasonTypeEnum.ApplicantsVehicleTypeNotNeeded;
export const STATUS_KEY_REJECTED_APPLICANT_INVALID_DOCUMENTATION =
  RejectionReasonTypeEnum.ApplicantInvalidDocumentation;
export const STATUS_KEY_REJECTED_OTHER = RejectionReasonTypeEnum.Other;

// Keep this order
export const STEPS_SORTED: ApplicationStepRoutes[] = [
  APPLICATION_STEP_PERSONAL_INFO,
  APPLICATION_STEP_DRIVER_SKILLS,
  APPLICATION_STEP_VEHICLE_DETAILS,
  APPLICATION_STEP_DRIVER_DETAILS,
  APPLICATION_STEP_REVIEW_SIGN,
  APPLICATION_STEP_DRIVER_CERTIFICATION,
  APPLICATION_STEP_DRIVER_BACKGROUND_CHECK,
];

// TODO remove ApplicationStepRoutes and use CurrentStepEnum instead
// That will avoids code duplication and will be easier to maintain
// Keep this order
export const CURRENT_STEPS_SORTED: CurrentStepEnum[] = [
  CurrentStepEnum.PersonalInfo,
  CurrentStepEnum.VehicleDriverSkills,
  CurrentStepEnum.VehicleDetails,
  CurrentStepEnum.DriverDetails,
  CurrentStepEnum.ReviewAndSign,
  CurrentStepEnum.DriverCertification,
];

import {
  DocumentRenewalStatusEnum,
  InsuranceTypeEnum,
} from '../../__generated__/graphql';
import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { OnboardingApplication } from '../../types/applicant.types';
import apiFetch from '../../util/api-fetch';
import { DocumentsAllowedToUpload } from './documents-renewals.util';

type DocumentsAllowedToUploadResponse = {
  document_driver_license_status: DocumentRenewalStatusEnum | null;
  document_insurance_status: DocumentRenewalStatusEnum | null;
};

const validateDocumentsAllowedToUpload =
  async (): Promise<DocumentsAllowedToUpload> => {
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };

    const url = `${API_BASE_URL}${API_RELATIVE_PATH}/documents_renewals/documents-statuses`;

    const response = await apiFetch<DocumentsAllowedToUploadResponse>({
      url,
      options,
    });

    return {
      documentDriverLicenseStatus:
        response?.document_driver_license_status || null,
      documentInsuranceStatus: response?.document_insurance_status || null,
    };
  };

export type DocumentRenewalsInput = {
  insurance_type?: InsuranceTypeEnum;
  insurance_expiration?: Date;
  driverLicenseExpiration?: Date;
};

export type PostDocumentRenewalsParams = {
  documentsDetails: DocumentRenewalsInput;
  signedIdsFilesInsurance: (string | null)[];
  signedIdsFilesLicenseFront: string | null;
  signedIdsFilesLicenseBack: string | null;
};

async function postDocumentRenewals({
  documentsDetails,
  signedIdsFilesInsurance,
  signedIdsFilesLicenseFront,
  signedIdsFilesLicenseBack,
}: PostDocumentRenewalsParams): Promise<OnboardingApplication | undefined> {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/documents_renewals`;

  const body = {
    driver_details: {
      driver_license_expiration: documentsDetails.driverLicenseExpiration,
      driver_license_front_image: signedIdsFilesLicenseFront,
      driver_license_back_image: signedIdsFilesLicenseBack,
    },
    vehicle_details: {
      vehicles_attributes: [
        {
          insurance_attributes: {
            insurance_type: documentsDetails.insurance_type,
            expiration: documentsDetails.insurance_expiration,
            documents: signedIdsFilesInsurance,
          },
        },
      ],
    },
  };

  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return apiFetch({ url, options });
}

export { postDocumentRenewals, validateDocumentsAllowedToUpload };

import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import CheckboxFormInput from '../../../components/inputs-form/checkbox-form-input';
import LabelForm from '../../../components/inputs-form/label-form';
import Typography from '../../../components/typography';
import { useTranslation } from '../../../hooks/use-translation';
import { DriverSkills as DriverSkillsLocale } from '../../../services/i18n/locale.types';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DriverSkillsInput } from '../../../types/driver-skills.input.types';
import { DriverCertification } from '../../../types/driver.types';
import {
  FEATURES_GROUPED_BY_VEHICLE_TYPE,
  VehicleFeature,
} from '../../../types/vehicle.types';
import {
  getDriverCertificationTranslationKey,
  getVehicleFeatureTranslationKey,
} from '../../available-openings/utils';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import VehicleTypeBox from '../components/vehicle-box';
import postDriverSkills, {
  PostDriverSkillsMutationParams,
} from './driver-skills.service';
import VEHICLE_TYPES_SORTED_BY_SIZE from './driver-skills.util';

const defaultValues: DriverSkillsInput = {
  vehicleType: undefined,
  features: [],
  skillsAndCertifications: [],
};

function DriverSkillsForm(): JSX.Element {
  const {
    onboardingApplication,
    setOnboardingApplication,
    onNextStep,
    checkForRejections,
  } = useOutletContext<OutletContextProps>();

  const { t } = useTranslation('driver-skills');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [featuresByVehicleTypeSelected, setFeaturesByVehicleTypeSelected] =
    useState<VehicleFeature[]>([]);

  // Form
  const { control, handleSubmit, watch, setValue } = useForm<DriverSkillsInput>(
    {
      defaultValues,
    },
  );

  useEffect(() => {
    if (onboardingApplication) {
      const currentVehicle = onboardingApplication.applicant.vehicles?.[0];
      if (currentVehicle) {
        setValue('vehicleId', currentVehicle.id);
        setValue('vehicleType', currentVehicle.vehicle_type);
        setValue('features', currentVehicle.features || []);
      } else {
        setValue(
          'vehicleType',
          onboardingApplication?.opening?.vehicle_type || undefined,
        );
      }
      setValue(
        'skillsAndCertifications',
        onboardingApplication?.applicant.driver_skills_selected_options?.map(
          skill => {
            return skill as DriverCertification;
          },
        ) || [],
      );
    }
  }, [onboardingApplication, setValue]);

  const [firstChangeAlreadyHappened, setFirstChangeAlreadyHappened] =
    useState<boolean>(false);
  const vehicleTypeWatcher = watch('vehicleType');

  useEffect(() => {
    const featuresGroup = FEATURES_GROUPED_BY_VEHICLE_TYPE.find(
      group => group.vehicleType === vehicleTypeWatcher,
    );
    setFeaturesByVehicleTypeSelected(featuresGroup?.features || []);

    if (
      !firstChangeAlreadyHappened &&
      vehicleTypeWatcher !== defaultValues.vehicleType
    )
      setFirstChangeAlreadyHappened(true);
  }, [
    vehicleTypeWatcher,
    setValue,
    firstChangeAlreadyHappened,
    onboardingApplication,
  ]);

  const postDriverSkillsMutation = useMutation({
    mutationFn: (params: PostDriverSkillsMutationParams) => {
      return postDriverSkills(params);
    },
    onSuccess: (response: OnboardingApplication | undefined) => {
      if (!response) {
        setSubmitLoading(false);
        return;
      }

      checkForRejections({
        application: response,
        onNoRejections: () => {
          setOnboardingApplication(response);
          onNextStep();
        },
      });
    },
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const onSubmit: SubmitHandler<DriverSkillsInput> = formData => {
    if (!onboardingApplication?.applicant.id) return;
    setSubmitLoading(true);

    postDriverSkillsMutation.mutate({
      driverSkills: formData,
      applicationId: onboardingApplication.applicant.id,
    });
  };

  return (
    <div>
      <form
        className="flex flex-col space-y-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Vehicle type */}
        <div className="mb-2">
          <Typography className="mb-2" size="sm" fontWeight="semibold">
            {t('SELECT_VEHICLE_TYPE')}
          </Typography>
          <Controller
            name="vehicleType"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('REQUIRED', { ns: 'common' }),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div className="grid grid-cols-2 gap-x-8 gap-y-4 md:grid-cols-3">
                {VEHICLE_TYPES_SORTED_BY_SIZE.map(vehicleType => (
                  <button
                    key={vehicleType}
                    data-testid={`button-vehicle-type-${vehicleType}`}
                    aria-label={`button-vehicle-type-${vehicleType}`}
                    type="button"
                    onClick={() => {
                      onChange(vehicleType);
                    }}
                  >
                    <VehicleTypeBox
                      vehicleType={vehicleType}
                      selected={value === vehicleType}
                    />
                  </button>
                ))}
              </div>
            )}
          />
        </div>

        {/* Vehicle features */}
        <div>
          <div className="mb-2">
            <LabelForm
              label={t('VEHICLE_FEATURE_TITTLE')}
              labelHelp={t('VEHICLE_FEATURE_SUBTITTLE')}
            />
          </div>
          <Controller
            name="features"
            control={control}
            render={({ field }) => {
              return (
                <div className="flex flex-col space-y-2">
                  {featuresByVehicleTypeSelected.map(feature => (
                    <CheckboxFormInput
                      key={feature}
                      onCheckedChange={(checked: boolean) => {
                        if (checked && !field.value.includes(feature)) {
                          const featuresUpdated = [...field.value];
                          featuresUpdated.push(feature);
                          field.onChange(featuresUpdated);
                        } else if (!checked && field.value.includes(feature)) {
                          const featuresUpdated = [...field.value].filter(
                            featureT => featureT !== feature,
                          );
                          field.onChange(featuresUpdated);
                        }
                      }}
                      checked={field.value.includes(feature)}
                      item={{
                        value: feature,
                        text: t(
                          getVehicleFeatureTranslationKey(
                            feature,
                          ) as keyof DriverSkillsLocale,
                        ),
                      }}
                    />
                  ))}
                </div>
              );
            }}
          />
        </div>

        {/* Driver skills and Certificates */}
        <div>
          <div className="mb-2">
            <LabelForm
              label={t('DRIVER_CERTIFICATIONS_TITTLE')}
              labelHelp={t('DRIVER_CERTIFICATIONS_SUBTITTLE')}
            />
          </div>
          <Controller
            name="skillsAndCertifications"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col space-y-2">
                {Object.values(DriverCertification).map(certification => {
                  const { text, secondaryText } =
                    getDriverCertificationTranslationKey(certification);
                  return (
                    <CheckboxFormInput
                      key={certification}
                      onCheckedChange={(checked: boolean) => {
                        if (checked && !field.value.includes(certification)) {
                          const certificationsUpdated = [...field.value];
                          certificationsUpdated.push(certification);
                          field.onChange(certificationsUpdated);
                        } else if (
                          !checked &&
                          field.value.includes(certification)
                        ) {
                          const featuresUpdated = [...field.value].filter(
                            certificationT => certificationT !== certification,
                          );
                          field.onChange(featuresUpdated);
                        }
                      }}
                      checked={field.value.includes(certification)}
                      item={{
                        value: certification,
                        text: t(text as keyof DriverSkillsLocale),
                        secondaryText: t(
                          secondaryText as keyof DriverSkillsLocale,
                        ),
                      }}
                    />
                  );
                })}
              </div>
            )}
          />
        </div>

        <ButtonSteps submitLoading={submitLoading} />
      </form>
    </div>
  );
}

export default DriverSkillsForm;

import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DocusignEmbedUrlResponse } from '../../../types/document.types';
import apiFetch from '../../../util/api-fetch';

export type PutDocusignEnvelopParams = {
  documentTemplateId: string;
  applicantId: string;
};

export const getDocusignDocumentUrl = async ({
  documentTemplateId,
  applicantId,
}: PutDocusignEnvelopParams): Promise<DocusignEmbedUrlResponse | undefined> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/documents/${applicantId}?template=${documentTemplateId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return apiFetch<DocusignEmbedUrlResponse>({ url, options });
};

export type ProcesingDocumentParams = {
  envelopeId: string;
  applicantId: string;
};

export const procesingDocument = async ({
  envelopeId,
  applicantId,
}: ProcesingDocumentParams): Promise<OnboardingApplication | undefined> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/processing-documents/${applicantId}/envelope/${envelopeId}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return apiFetch<OnboardingApplication>({ url, options });
};

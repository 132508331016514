import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import { PersonalInfoInput } from '../../../types/personal-info.types';
import apiFetch from '../../../util/api-fetch';

const postPersonalInfo = async (
  personalInfoInput: PersonalInfoInput,
  openingId: string,
  applicantId: string,
): Promise<OnboardingApplication | undefined> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/applicant-personal-info/${applicantId}`;

  const options = {
    method: 'POST',
    body: JSON.stringify({
      applicant_personal_info: {
        ...personalInfoInput,
      },
      opening_id: openingId,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return apiFetch<OnboardingApplication>({ url, options });
};

export default postPersonalInfo;

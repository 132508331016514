import Intercom from '@intercom/messenger-js-sdk';
import { useContext, useEffect, useState } from 'react';
import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { CurrentUserContext } from '../../providers/current-user.context';
import apiFetch from '../../util/api-fetch';

async function fetchIntercomEnvVar() {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intercom-info`;

  return apiFetch<{ intercom_app_id: string }>({ url });
}

function IntercomContactWrapper({ children }: { children: React.ReactNode }) {
  const { currentUser, existingApplications } = useContext(CurrentUserContext);

  const [intercomAppId, setIntercomAppId] = useState<string | null>(null);

  useEffect(() => {
    if (!intercomAppId) {
      fetchIntercomEnvVar().then(envVar => {
        if (envVar) setIntercomAppId(envVar.intercom_app_id);
      });
    }
  }, [intercomAppId]);

  if (
    currentUser &&
    currentUser.email &&
    intercomAppId &&
    existingApplications.length > 0
  ) {
    Intercom({
      app_id: intercomAppId,
      email: currentUser.email,
    });
  }

  return <div>{children}</div>;
}

export default IntercomContactWrapper;

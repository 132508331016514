import { enqueueSnackbar } from 'notistack';

const displayToast = (
  message: string,
  variant: 'success' | 'error' | 'warning' | 'info' = 'error',
) => {
  enqueueSnackbar(message, {
    variant,
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
    autoHideDuration: 5000,
  });
};

type ApiFetchProps = {
  url: string;
  options?: RequestInit;
  onForbidden?: () => void;
  onNotFound?: () => void;
  redirectOnForbidden?: boolean;
  redirectOnNotFound?: boolean;
  showToast?: boolean;
};

async function apiFetch<R>({
  url,
  options = {},
  onForbidden = () => {},
  onNotFound = () => {},
  redirectOnForbidden = false,
  redirectOnNotFound = false,
  showToast = true,
}: ApiFetchProps): Promise<R | undefined> {
  try {
    const response = await fetch(url, options);

    if (response.status === 401) {
      const currentPath = window.location.pathname;
      window.location.href = `/sign-in?redirectUrl=${encodeURIComponent(currentPath)}`;

      return undefined;
    }

    if ([403, 405].includes(response.status)) {
      if (redirectOnForbidden) window.location.href = '/403';

      if (showToast) displayToast('Action Forbidden');

      onForbidden();

      return undefined;
    }

    if (response.status === 404) {
      if (redirectOnNotFound) window.location.href = '/404';

      if (showToast) displayToast('Resource Not Found');

      onNotFound();

      return undefined;
    }

    if (!response.ok && ![401, 403, 404, 405].includes(response.status)) {
      throw new Error(
        `HTTP error! status: ${response.status} ${response.statusText}`,
      );
    }

    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Fetch error:', error);
    throw error;
  }
}

export default apiFetch;

import MainContentCard from '../../components/layout/main-layout/main-content-card';
import { useTranslation } from '../../hooks/use-translation';
import {
  DocumentsAllowedToUpload,
  needsReview,
  PageStatus,
} from './documents-renewals.util';

type DocumentsRenewalsStatusProps = {
  documentsAllowedToUploadData: DocumentsAllowedToUpload;
  pageStatus: PageStatus;
};

function DocumentsRenewalsStatus({
  documentsAllowedToUploadData,
  pageStatus,
}: DocumentsRenewalsStatusProps) {
  const { documentDriverLicenseStatus, documentInsuranceStatus } =
    documentsAllowedToUploadData;

  const { t } = useTranslation('document-renewals');

  let title = '';
  let content = '';

  if (pageStatus === PageStatus.UNDER_REVIEW) {
    const driverLicenseUnderReview =
      !!documentDriverLicenseStatus && needsReview(documentDriverLicenseStatus);
    const insuranceUnderReview =
      !!documentInsuranceStatus && needsReview(documentInsuranceStatus);

    if (driverLicenseUnderReview && !insuranceUnderReview) {
      title = t('STATUS_THANKS_FOR_SUBMITTING_LICENSE_TITLE');
      content = t('STATUS_THANKS_FOR_SUBMITTING_LICENSE_CONTENT');
    } else if (!driverLicenseUnderReview && insuranceUnderReview) {
      title = t('STATUS_THANKS_FOR_SUBMITTING_INSURANCE_TITLE');
      content = t('STATUS_THANKS_FOR_SUBMITTING_INSURANCE_CONTENT');
    } else if (driverLicenseUnderReview && insuranceUnderReview) {
      title = t('STATUS_THANKS_FOR_SUBMITTING_LICENSE_AND_INSURANCE_TITLE');
      content = t('STATUS_THANKS_FOR_SUBMITTING_LICENSE_AND_INSURANCE_CONTENT');
    }
  } else if (pageStatus === PageStatus.REJECTED) {
    title = t('STATUS_REJECTED_TITLE');
    content = t('STATUS_REJECTED_CONTENT');
  } else if (pageStatus === PageStatus.APPROVED) {
    title = t('STATUS_APPROVED_TITLE');
    content = t('STATUS_APPROVED_CONTENT');
  }

  return (
    <MainContentCard
      title={title}
      titleSize="xl"
      content={<div className="flex justify-center text-sm">{content}</div>}
    />
  );
}

export default DocumentsRenewalsStatus;

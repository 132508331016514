import { Alert, AlertDescription, Button } from '@dispatch-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { LuAlertCircle } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { ApplicantStatusEnum } from '../../__generated__/graphql';
import {
  APPLICATION_STEP_PERSONAL_INFO,
  DOCUMENTS_RENEWALS,
  STEPS,
} from '../../constants/routes.constants';
import { useTranslation } from '../../hooks/use-translation';
import { validateDocumentsAllowedToUpload } from '../../pages/documents-renewals/documents-renewals.service';
import { uploadNeeded } from '../../pages/documents-renewals/documents-renewals.util';
import { CurrentUserContext } from '../../providers/current-user.context';
import { OnboardingApplication } from '../../types/applicant.types';
import {
  validateAlreadyApplied,
  validateAlreadyDriver,
  validateBackgroundCheckFailed,
  validateIdentityVerificationBackgroundCheckFailed,
} from './util';

function ApplicationItems({
  applications,
  buttonText,
}: {
  applications: OnboardingApplication[];
  buttonText: string;
}): JSX.Element {
  return (
    <ul className="list-disc ml-6">
      {applications.map(application => (
        <li key={application.applicant.id} className="">
          {`${application.opening?.location} - ${application.opening?.position}`}
          <Button
            variant="link"
            data-testid="non-rejected-resume-button"
            className="p-0 h-[30px] font-bold ml-2"
            onClick={() => {
              window.location.href = `/${STEPS}/${APPLICATION_STEP_PERSONAL_INFO}/applicant/${application.applicant.id}`;
            }}
          >
            {buttonText}
          </Button>
        </li>
      ))}
    </ul>
  );
}

type MultipleApplicationsAlertProps = {
  ongoingApplication?: OnboardingApplication | undefined;
};

function MultipleApplicationsAlert({
  ongoingApplication,
}: MultipleApplicationsAlertProps): JSX.Element {
  const { t } = useTranslation('multiple-applications');
  const navigate = useNavigate();
  const { existingApplications } = useContext(CurrentUserContext);

  const backgroundCheckFailed =
    validateBackgroundCheckFailed(existingApplications);

  const identityVerificationBackgroundCheckFailed =
    validateIdentityVerificationBackgroundCheckFailed(existingApplications);

  const alreadyDriver = validateAlreadyDriver(existingApplications);

  const alreadyApplied = validateAlreadyApplied(existingApplications);

  const { data: documentsAllowedToUploadData } = useQuery(
    ['validate-documents-allowed-to-upload'],
    () => validateDocumentsAllowedToUpload(),
    {
      enabled: alreadyDriver,
      retry: false,
    },
  );

  if (identityVerificationBackgroundCheckFailed)
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {t('IDENTITY_VERIFICATION_BACKGROUND_CHECK_FAIL')}
        </AlertDescription>
      </Alert>
    );

  if (
    !existingApplications ||
    existingApplications.length === 0 ||
    (existingApplications.length === 1 && ongoingApplication)
  )
    return <div />;

  const applicationsByUser = ongoingApplication
    ? existingApplications.filter(
        application =>
          application.applicant.id !== ongoingApplication?.applicant.id,
      )
    : existingApplications;

  if (alreadyDriver) {
    const needsToUploadDocuments =
      uploadNeeded(documentsAllowedToUploadData?.documentDriverLicenseStatus) ||
      uploadNeeded(documentsAllowedToUploadData?.documentInsuranceStatus);

    if (needsToUploadDocuments)
      return (
        <Alert className="bg-yellow-200">
          <AlertDescription className="flex items-center">
            <span className="grow">{t('DOCUMENTS_EXPIRED')}</span>
            <Button
              variant="link"
              data-testid="non-rejected-resume-button"
              className="p-0 h-[30px] font-bold ml-2"
              onClick={() => {
                navigate(`/${DOCUMENTS_RENEWALS}`);
              }}
            >
              {t('DOCUMENTS_EXPIRED_LINK')}
            </Button>
          </AlertDescription>
        </Alert>
      );

    return (
      <Alert variant="destructive">
        <AlertDescription>{t('ALREADY_DRIVER')}</AlertDescription>
      </Alert>
    );
  }

  if (alreadyApplied)
    return (
      <Alert variant="destructive">
        <AlertDescription>{t('ALREADY_APPLIED')}</AlertDescription>
      </Alert>
    );

  if (backgroundCheckFailed)
    return (
      <Alert variant="destructive">
        <AlertDescription>{t('BACKGROUND_CHECK_FAIL')}</AlertDescription>
      </Alert>
    );

  const rejectedApplications = applicationsByUser.filter(
    application =>
      application.applicant.status === ApplicantStatusEnum.ApplicantRejected,
  );

  const nonRejectedApplications = applicationsByUser.filter(
    application =>
      application.applicant.status !== ApplicantStatusEnum.ApplicantRejected,
  );

  const onlyRejectedApplications =
    rejectedApplications.length > 0 && nonRejectedApplications.length === 0;

  return (
    <Alert className={onlyRejectedApplications ? 'default' : 'bg-yellow-200'}>
      <AlertDescription>
        {nonRejectedApplications && nonRejectedApplications.length > 0 && (
          <>
            {t('THERE_ARE_APPLICATIONS_FOR_OTHER_POSITIONS')}:
            <ApplicationItems
              applications={nonRejectedApplications}
              buttonText="Resume"
            />
          </>
        )}
        {rejectedApplications && rejectedApplications.length > 0 && (
          <>
            {t('OTHER_POSITIONS_REJECTED')}:
            <ApplicationItems
              applications={rejectedApplications}
              buttonText="See details"
            />
          </>
        )}
        <div className="flex items-center">
          <LuAlertCircle />
          <div className="mx-2">{t('CAN_ONLY_APPLY_FOR_ONE_POSITION')}</div>
        </div>
      </AlertDescription>
    </Alert>
  );
}

export default MultipleApplicationsAlert;

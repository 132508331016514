import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import FormErrorLabel from '../form-error-label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../select/select';
import LabelForm from './label-form';
import { getInputErrorClassName } from './styles.util';

export type SelectItemProps = {
  value: string;
  text: string;
};

function SelectInputForm<T extends FieldValues>({
  control,
  controllerProps,
  error,
  items,
  label,
  labelHelp,
  required = false,
}: {
  control: Control<T>;
  controllerProps: Omit<UseControllerProps<T>, 'data-testid'>;
  error?: FieldError;
  items: SelectItemProps[];
  label?: string;
  labelHelp?: string;
  required?: boolean;
}) {
  const inputClassName = getInputErrorClassName(!!error);

  return (
    <div>
      <div className="mb-1">
        <LabelForm
          label={label}
          labelHelp={labelHelp}
          required={required}
          hasError={!!error}
        />
      </div>
      <Controller
        control={control}
        {...controllerProps}
        render={({ field: { onChange, value } }) => (
          <Select value={value} onValueChange={newValue => onChange(newValue)}>
            <SelectTrigger
              className={`w-full ${inputClassName}`}
              data-testid={`select-${controllerProps.name}`}
            >
              <SelectValue placeholder="Select state" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {items.map(item => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.text}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
      />
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default SelectInputForm;

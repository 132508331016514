import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ButtonSpinner from '../../components/button-spinner';
import FormErrorField from '../../components/form-error-label';
import CalendarInputForm from '../../components/inputs-form/calendar-input-form';
import LabelForm from '../../components/inputs-form/label-form';
import SelectInputForm from '../../components/inputs-form/select-input-form';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import Spinner from '../../components/spinner';
import Typography from '../../components/typography';
import UploadPhoto from '../../components/upload-file/upload-file';
import useFileUploadS3, {
  DocumentToUpload,
  HandleImageUploadResponse,
  UploadMultipleImagesParams,
} from '../../hooks/use-file-upload-s3';
import { useTranslation } from '../../hooks/use-translation';
import { DocumentType } from '../../types/document.types';
import DocumentsRenewalsStatus from './documents-renewals-status';
import {
  DocumentRenewalsInput,
  postDocumentRenewals,
  PostDocumentRenewalsParams,
  validateDocumentsAllowedToUpload,
} from './documents-renewals.service';
import {
  getPageStatus,
  PageStatus,
  uploadNeeded,
} from './documents-renewals.util';

function DocumentsRenewalsForm(): JSX.Element {
  const { t } = useTranslation('vehicle-details');
  const { handleMultipleImageUpload } = useFileUploadS3();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    isLoading,
    data: documentsAllowedToUploadData,
    refetch,
  } = useQuery(
    ['validate-documents-allowed-to-upload'],
    () => validateDocumentsAllowedToUpload(),
    { retry: false },
  );

  const { driverLicenseNeeded, insuranceNeeded, pageStatus } = useMemo(() => {
    if (!documentsAllowedToUploadData) {
      return {
        driverLicenseNeeded: false,
        insuranceNeeded: false,
        pageStatus: PageStatus.SHOW_FORM,
      };
    }

    const { documentDriverLicenseStatus, documentInsuranceStatus } =
      documentsAllowedToUploadData;

    const documentsStatuses = [];

    if (documentDriverLicenseStatus)
      documentsStatuses.push(documentDriverLicenseStatus);

    if (documentInsuranceStatus)
      documentsStatuses.push(documentInsuranceStatus);

    return {
      driverLicenseNeeded: uploadNeeded(documentDriverLicenseStatus),
      insuranceNeeded: uploadNeeded(documentInsuranceStatus),
      pageStatus: getPageStatus(documentsStatuses),
    };
  }, [documentsAllowedToUploadData]);

  const documentRenewalsMutation = useMutation({
    mutationFn: (params: PostDocumentRenewalsParams) => {
      return postDocumentRenewals(params);
    },
    onSuccess: () => {
      refetch();
      setSubmitLoading(false);
    },
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<DocumentRenewalsInput>({
    defaultValues: {
      insurance_type: undefined,
      insurance_expiration: undefined,
      driverLicenseExpiration: undefined,
    },
  });

  const [proofOfInsurance, setProofOfInsurance] = useState<FileList | null>(
    null,
  );
  const [driverLicenseFront, setDriverLicenseFront] = useState<FileList | null>(
    null,
  );
  const [driverLicenseBack, setDriverLicenseBack] = useState<FileList | null>(
    null,
  );

  if (documentsAllowedToUploadData && pageStatus !== PageStatus.SHOW_FORM) {
    return (
      <DocumentsRenewalsStatus
        pageStatus={pageStatus}
        documentsAllowedToUploadData={documentsAllowedToUploadData}
      />
    );
  }

  const onSubmit: SubmitHandler<DocumentRenewalsInput> = async formData => {
    if (driverLicenseNeeded && (driverLicenseFront || []).length === 0) return;
    if (driverLicenseNeeded && (driverLicenseBack || []).length === 0) return;
    if (insuranceNeeded && (proofOfInsurance || []).length === 0) return;

    setSubmitLoading(true);

    const driverLicenseFrontFiles: DocumentToUpload[] = Array.from(
      driverLicenseFront || [],
    ).map(file => ({
      documentType: DocumentType.DRIVER_LICENSE_FRONT,
      file,
    }));

    const driverLicenseBackFiles: DocumentToUpload[] = Array.from(
      driverLicenseBack || [],
    ).map(file => ({
      documentType: DocumentType.DRIVER_LICENSE_BACK,
      file,
    }));

    const proofOfInsuranceFiles: DocumentToUpload[] = Array.from(
      proofOfInsurance || [],
    ).map(file => ({
      documentType: DocumentType.PROOF_OF_INSURANCE,
      file,
    }));

    const multipleDocuments: UploadMultipleImagesParams = {
      documents: [
        ...driverLicenseBackFiles,
        ...driverLicenseFrontFiles,
        ...proofOfInsuranceFiles,
      ],
    };

    handleMultipleImageUpload(multipleDocuments)
      .then((responses: HandleImageUploadResponse[]) => {
        const newSignedIdsFilesInsurance: (string | null)[] = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.PROOF_OF_INSURANCE,
          )
          .map(res => res.signedId);

        const newSignedIdsFilesLicenseFront: (string | null)[] = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType ===
              DocumentType.DRIVER_LICENSE_FRONT,
          )
          .map(res => res.signedId);

        const newSignedIdsFilesLicenseBack: (string | null)[] = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.DRIVER_LICENSE_BACK,
          )
          .map(res => res.signedId);

        return Promise.resolve({
          documentsDetails: formData,
          signedIdsFilesInsurance: newSignedIdsFilesInsurance,
          signedIdsFilesLicenseFront: newSignedIdsFilesLicenseFront[0] || null,
          signedIdsFilesLicenseBack: newSignedIdsFilesLicenseBack[0] || null,
        });
      })
      .then((response: PostDocumentRenewalsParams) => {
        documentRenewalsMutation.mutate(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(
          'Error while submitting files - documents renewals',
          error,
        );
        setSubmitLoading(false);
        enqueueSnackbar('Error while submitting files');
      });
  };

  const driverLicenseFrontRequired =
    isSubmitted &&
    driverLicenseNeeded &&
    (driverLicenseFront || []).length === 0;

  const dirverLicenseBackRequiered =
    isSubmitted &&
    driverLicenseNeeded &&
    (driverLicenseBack || []).length === 0;

  const proofOfInsuranceRequired =
    isSubmitted && insuranceNeeded && (proofOfInsurance || []).length === 0;

  const hasErrors =
    Object.keys(errors).length > 0 ||
    driverLicenseFrontRequired ||
    dirverLicenseBackRequiered ||
    proofOfInsuranceRequired;

  let title;
  if (driverLicenseNeeded && !insuranceNeeded)
    title = t('PAGE_TITLE_DRIVER_LICENSE', { ns: 'document-renewals' });
  else if (!driverLicenseNeeded && insuranceNeeded)
    title = t('PAGE_TITLE_VEHICLE_INSURANCE', { ns: 'document-renewals' });
  else title = t('PAGE_TITLE_BOTH', { ns: 'document-renewals' });

  return (
    <MainContentCard
      title={title}
      content={
        <form
          className="flex flex-col space-y-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          {isLoading && (
            <div className="h-screen flex justify-center">
              <Spinner />
            </div>
          )}
          {/* Driver License */}
          {!isLoading && driverLicenseNeeded && (
            <>
              <div>
                <Typography size="xl" className="mb-4 text-xl" color="primary">
                  {t('DOCUMENT_DRIVER_LICENSE_TITLE', { ns: 'driver-details' })}
                </Typography>

                <div>
                  <div className="mb-4">
                    <LabelForm
                      label={t('DOCUMENT_DRIVER_LICENSE_FRONT_TITLE', {
                        ns: 'driver-details',
                      })}
                      labelHelp={t('DOCUMENT_DRIVER_LICENSE_FRONT_DESC', {
                        ns: 'driver-details',
                      })}
                      required
                      hasError={driverLicenseFrontRequired}
                      labelClassName="font-normal text-base text-primary"
                    />
                  </div>
                  <UploadPhoto
                    inputId="input-license-front"
                    existingFiles={[]}
                    onRemoveExistingFile={() => {}}
                    files={driverLicenseFront}
                    setFiles={setDriverLicenseFront}
                    multiple={false}
                    hasError={driverLicenseFrontRequired}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="mb-4">
                    <LabelForm
                      label={t('DOCUMENT_DRIVER_LICENSE_BACK_TITLE', {
                        ns: 'driver-details',
                      })}
                      labelHelp={t('DOCUMENT_DRIVER_LICENSE_BACK_DESC', {
                        ns: 'driver-details',
                      })}
                      required
                      hasError={dirverLicenseBackRequiered}
                      labelClassName="font-normal text-base text-primary"
                    />
                  </div>
                  <UploadPhoto
                    inputId="input-license-back"
                    existingFiles={[]}
                    onRemoveExistingFile={() => {}}
                    files={driverLicenseBack}
                    setFiles={setDriverLicenseBack}
                    multiple={false}
                    hasError={dirverLicenseBackRequiered}
                  />
                </div>
              </div>

              {/* License Expiration */}
              <div className="flex flex-col space-y-4">
                <CalendarInputForm
                  label={t('DRIVER_LICENSE_EXPIRATION', {
                    ns: 'driver-details',
                  })}
                  control={control}
                  error={errors.driverLicenseExpiration}
                  setValue={(newDate: Date | undefined) => {
                    setValue('driverLicenseExpiration', newDate);
                    clearErrors('driverLicenseExpiration');
                  }}
                  controllerProps={{
                    name: 'driverLicenseExpiration',
                    rules: {
                      required: {
                        value: true,
                        message: t('REQUIRED_FIELD', {
                          ns: 'common',
                          replace: {
                            fieldName: t('DRIVER_LICENSE_EXPIRATION', {
                              ns: 'driver-details',
                            }),
                          },
                        }),
                      },
                    },
                  }}
                  required
                />
              </div>
            </>
          )}
          {/* Proof of insurance */}
          {!isLoading && insuranceNeeded && (
            <>
              <div>
                <div className="mb-4">
                  <LabelForm
                    label={t('PROOF_OF_INSURANCE_LABEL')}
                    labelHelp={t('PROOF_OF_INSURANCE_DESC')}
                    required
                    hasError={proofOfInsuranceRequired}
                    labelClassName="font-normal text-base text-primary"
                  />
                </div>
                <UploadPhoto
                  inputId="input-proof-of-insurance"
                  existingFiles={[]}
                  onRemoveExistingFile={() => {}}
                  files={proofOfInsurance}
                  setFiles={setProofOfInsurance}
                  hasError={proofOfInsuranceRequired}
                />
              </div>

              {/* Insurance information */}

              <div className="flex flex-col space-y-4">
                <SelectInputForm
                  label={t('INSURANCE_TYPE_LABEL')}
                  error={errors.insurance_type}
                  control={control}
                  controllerProps={{
                    name: 'insurance_type',
                    rules: {
                      required: {
                        value: true,
                        message: t('REQUIRED_FIELD', {
                          ns: 'common',
                          replace: { fieldName: t('INSURANCE_TYPE_LABEL') },
                        }),
                      },
                    },
                  }}
                  items={[
                    { value: 'commercial', text: 'Commercial' },
                    { value: 'personal', text: 'Personal' },
                  ]}
                  required
                />

                <div>
                  <CalendarInputForm
                    label={t('INSURANCE_EXPIRATION_LABEL')}
                    control={control}
                    error={errors.insurance_expiration}
                    setValue={(newDate: Date | undefined) => {
                      setValue('insurance_expiration', newDate);
                      clearErrors('insurance_expiration');
                    }}
                    required
                    controllerProps={{
                      name: 'insurance_expiration',
                      rules: {
                        required: {
                          value: true,
                          message: t('REQUIRED_FIELD', {
                            ns: 'common',
                            replace: {
                              fieldName: t('INSURANCE_EXPIRATION_LABEL'),
                            },
                          }),
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex w-full">
            <div className="grow" />

            <div className="flex flex-col w-[220px] items-end">
              {hasErrors && (
                <div className="mb-2">
                  <FormErrorField>
                    {t('REQUIRED_FIX', { ns: 'common' })}
                  </FormErrorField>
                </div>
              )}
              <ButtonSpinner
                data-testid="next-submit"
                type="submit"
                size="lg"
                variant="default"
                loading={submitLoading}
              >
                {t('SUBMIT', { ns: 'common' })}
              </ButtonSpinner>
            </div>
          </div>
        </form>
      }
    />
  );
}

export default DocumentsRenewalsForm;

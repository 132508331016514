import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { OnboardingApplication } from '../../types/applicant.types';
import apiFetch from '../../util/api-fetch';

const getOnboardingApplication = async (
  applicantId: string,
): Promise<OnboardingApplication | undefined> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/applicants/${applicantId}`;

  return apiFetch<OnboardingApplication>({
    url,
    redirectOnForbidden: true,
    redirectOnNotFound: true,
    showToast: false,
  });
};

export default getOnboardingApplication;

import * as Sentry from '@sentry/react';
import { createContext, useMemo, useState } from 'react';
import { API_BASE_URL, API_RELATIVE_PATH } from '../constants/util.constants';
import { OnboardingApplication } from '../types/applicant.types';
import { BasicUserData } from '../types/personal-info.types';
import apiFetch from '../util/api-fetch';

const getCurrentUser = async (): Promise<BasicUserData | undefined> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/current-user`;

  return apiFetch<BasicUserData>({ url });
};

const getApplicationsByUser = async (): Promise<OnboardingApplication[]> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/applicants`;

  return (
    (await apiFetch<OnboardingApplication[]>({ url, showToast: false })) || []
  );
};

type CurrentUserContextType = {
  currentUser: BasicUserData | undefined;
  loadingCurrentUser: boolean;
  fetchCurrentUser: () => void;
  // This is used to show alerts to the applicant when they have more than one application
  existingApplications: OnboardingApplication[];
};

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: undefined,
  loadingCurrentUser: false,
  existingApplications: [],
  fetchCurrentUser: () => {},
});

export function CurrentUserProvider({
  isAdmin = false,
  children,
}: {
  isAdmin?: boolean;
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<BasicUserData | undefined>(undefined);
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(false);
  const [existingApplications, setExistingApplications] = useState<
    OnboardingApplication[]
  >([]);

  const context = useMemo(() => {
    const fetchCurrentUser = () => {
      setLoadingCurrentUser(true);
      getCurrentUser()
        .then(currentUserData => {
          if (currentUserData) {
            setUser({
              id: currentUserData.id,
              email: currentUserData.email,
              first_name: currentUserData.first_name,
              last_name: currentUserData.last_name,
              full_name: currentUserData.full_name,
            });
            Sentry.setUser({ id: currentUserData.id });
          }

          if (isAdmin) return [];

          return currentUserData?.id ? getApplicationsByUser() : [];
        })
        .then((applicationsByUser: OnboardingApplication[]) => {
          if (!isAdmin && applicationsByUser)
            setExistingApplications(applicationsByUser);
        })
        .finally(() => {
          setLoadingCurrentUser(false);
        });
    };

    return {
      currentUser: user,
      loadingCurrentUser,
      fetchCurrentUser,
      existingApplications,
    };
  }, [existingApplications, isAdmin, loadingCurrentUser, user]);

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
}

import apiFetch from '../../util/api-fetch';

const authenticateWithMagicAuth = async (input: {
  email: string;
  code: string;
}) => {
  return apiFetch({
    url: '/api/v1/magic_auth/authenticate',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    },
  });
};

const initiateAuth = async (input: { email: string }) => {
  return apiFetch({
    url: '/api/v1/magic_auth',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    },
  });
};

export { authenticateWithMagicAuth, initiateAuth };
